























































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import _ from 'lodash';
import api from '@/api';
import type { ValacBox, WishcardParameters, Wishcard } from '@/models';
import type { NavigationGuardNext, Route } from 'vue-router';
import { ValidationObserver } from 'vee-validate';
import ValacBoxLayout from '@/components/layouts/ValacBoxLayout.vue';
import FieldWithValidation from '@/components/common/FieldWithValidation.vue';
import WishcardForm from '@/components/wishcards/WishcardForm.vue';

@Component({
    name: 'WishcardSubmit',
    components: {
        ValidationObserver,
        ValacBoxLayout,
        FieldWithValidation,
        WishcardForm,
    },
    metaInfo(this: WishcardSubmit) {
        return {
            title: this.valacBox?.name,
        };
    },
})
export default class WishcardSubmit extends Vue {
    @Ref()
    private wishcardForm: WishcardForm;

    private valacBox: ValacBox | null = null;

    private form: WishcardParameters = {
        title: '',
        text: '',
        email: '',
        sender: '',
    };
    private attachments: File[] = [];

    private existingWishcard: Wishcard | null = null;
    private wishcardLoading = false;

    private creatingWishcard = {
        title: '',
        text: '',
        sender: '',
    };
    private creatingWishcardAttachments: File[] = [];

    private openLoading = false;
    private isCreatingWishcard = false;
    private isOpeningWishcard = false;

    private createSubmitted = false;

    private fetchWishcardDebounced = _.debounce(this.fetchWishcard, 500);

    private get valacBoxId(): string {
        return this.$route.params.id;
    }
    private get valacBoxLogoUrl(): string {
        return api.valacBoxLogoUrl(this.valacBoxId);
    }

    private get isFormOpen(): boolean {
        return this.isCreatingWishcard || this.isOpeningWishcard;
    }

    private get canCreateWishcard(): boolean {
        return !this.wishcardLoading && this.existingWishcard == null;
    }
    private get canOpenWishcard(): boolean {
        return !this.wishcardLoading && this.existingWishcard != null;
    }

    @Watch('form.email')
    private async onEmailChange() {
        if (this.isFormOpen) {
            this.closeForm();
        }

        this.wishcardLoading = true;
        this.fetchWishcardDebounced();
    }
    @Watch('isFormOpen')
    private async onCloseForm(value: boolean) {
        if (!value) {
            this.wishcardForm.resetForm();
        }
    }
    @Watch('isCreatingWishcard')
    private async onCreateWishcardChange(value: boolean) {
        if (value) {
            this.loadCreateState();
        } else {
            if (this.createSubmitted) {
                this.createSubmitted = false;
                this.clearCreateState();
            } else {
                this.storeCreateState();
            }
        }
    }

    public async beforeRouteEnter(
        to: Route,
        from: Route,
        next: NavigationGuardNext<WishcardSubmit>
    ): Promise<void> {
        const valacBox = await api.getValacBox(to.params.id);

        next((vm: WishcardSubmit) => (vm.valacBox = valacBox));
    }

    private closeForm(): void {
        this.isCreatingWishcard = false;
        this.isOpeningWishcard = false;
    }

    private clearEmail(): void {
        this.form.email = '';
    }

    private storeCreateState(): void {
        this.creatingWishcardAttachments = [...this.attachments];
        this.creatingWishcard = { ...this.form };
    }
    private loadCreateState(): void {
        this.attachments = [...this.creatingWishcardAttachments];
        this.form.title = this.creatingWishcard.title;
        this.form.sender = this.creatingWishcard.sender;
        this.form.text = this.creatingWishcard.text;
    }
    private clearCreateState(): void {
        this.creatingWishcard = {
            title: '',
            text: '',
            sender: '',
        };
        this.creatingWishcardAttachments = [];
    }

    private async fetchWishcard(): Promise<void> {
        if (!this.form.email) {
            this.existingWishcard = null;
            return;
        }

        this.wishcardLoading = true;

        try {
            this.existingWishcard = await api.getValacBoxWishcard(
                this.valacBoxId,
                this.form.email
            );
        } catch (error) {
            this.existingWishcard = null;
        } finally {
            this.wishcardLoading = false;
        }
    }
    private async fetchAttachments(): Promise<void> {
        const wishcard = this.existingWishcard;

        if (wishcard != null) {
            this.attachments = [];

            for (const attachment of wishcard.attachments) {
                const file = await api.getValacBoxWishcardFile(
                    this.valacBoxId,
                    wishcard.email,
                    attachment.name
                );

                if (file != null) {
                    this.attachments.push(file);
                }
            }
        }
    }

    private onClickCreate(): void {
        this.isCreatingWishcard = !this.isCreatingWishcard;
    }
    private async onClickOpen(): Promise<void> {
        if (this.isOpeningWishcard) {
            this.isOpeningWishcard = false;
        } else {
            this.openLoading = true;

            try {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.form = { ...this.existingWishcard! };

                await this.fetchAttachments();

                this.isOpeningWishcard = true;
            } catch (error) {
                // TODO
            } finally {
                this.openLoading = false;
            }
        }
    }
    private onSubmitted(): void {
        this.fetchWishcard();

        if (this.isCreatingWishcard) {
            this.createSubmitted = true;
        }

        this.closeForm();
    }
}
