export default {
    'dashboard.title': 'iValacBOX',
    'dashboard.description':
        'Here you can access existing boxes or create a new one.',
    'dashboard.search': 'Search for id, title, date',
    'dashboard.view': 'View',
    'dashboard.create': 'New',
    'valacBoxTable.id': 'ID',
    'valacBoxTable.name': 'Title',
    'valacBoxTable.expirationDate': 'Exp. date / Status',
    'valacBoxTable.wishcardCount': 'Number of messages',
    'valacBoxTable.edit': 'Edit',
    'valacBoxTable.empty': 'No results found',
    'wishcardList.expirationDate': 'Expiry date: ',
    'wishcardList.search': 'Search for sender, title, email',
    'wishcardList.play': 'Play',
    'wishcardList.submit': 'Submit',
    'wishcardSubmit.title': 'iValacBOX: {0} - {1}',
    'wishcardSubmit.subtitle': 'Submit',
    'wishcardSubmit.welcome': 'Welcome to iValacBOX: ',
    'wishcardSubmit.description.1': 'This iValacBOX collects messages for: ',
    'wishcardSubmit.description.2':
        'Here you can create a farewell card using an image, text or video',
    'wishcardSubmit.description.3': 'This page will be active until: ',
    'wishcardSubmit.email': 'Contact email',
    'wishcardSubmit.email.description':
        'Please create or open your existing farewell card with your contact email address',
    'wishcardSubmit.email.remark': '*only one farewell card per email',
    'wishcardSubmit.create': 'Create',
    'wishcardSubmit.open': 'Open',
    'wishcardSubmit.expired':
        'The iValacBOX <b>{0}</b> closed in <b>{1}</b> and cannot be accessed.',
    'wishcardTable.sender': 'From',
    'wishcardTable.title': 'Title',
    'wishcardTable.email': 'Email',
    'wishcardTable.attachmentCount': 'Number of assets',
    'wishcardTable.empty': 'No submissions found',
    'wishcardForm.sender': 'Sender',
    'wishcardForm.sender.label': "Who's it from?",
    'wishcardForm.sender.placeholder': 'Group, Team or person',
    'wishcardForm.content': 'Please fill out your farewell card',
    'wishcardForm.content.title': 'Title',
    'wishcardForm.content.text': 'Write your own text',
    'wishcardForm.submit': 'Save',
    'wishcardForm.submit.success': 'Farewell card saved succesfully',
    'wishcardForm.submit.error':
        'Farewell card could not be submitted, please verify that all fields are valid and try again',
};
