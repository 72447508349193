import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { Store } from 'vuex';
import { authStore } from '@/store/modules/auth';
import { ModalProgrammatic as Modal } from 'buefy';
import PasswordModal from '@/components/modals/PasswordModal.vue';

export function registerGlobalAuthGuard(
    router: VueRouter,
    store: Store<any>
): void {
    router.beforeEach(
        async (to: Route, from: Route, next: NavigationGuardNext) => {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                const authCtx = authStore.context(store);

                if (authCtx.state.isAuthenticated) {
                    next();
                } else {
                    Modal.open({
                        component: PasswordModal,
                        hasModalCard: true,
                        canCancel: false,
                        events: {
                            login: () => {
                                authCtx.commit('authenticate');
                                next();
                            },
                        },
                    });
                }
            } else {
                next();
            }
        }
    );
}
