






















































import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';
import FileUtilsMixin from '@/components/mixins/FileUtilsMixin';
import ImageViewerModal from '@/components/modals/ImageViewerModal.vue';

@Component({
    name: 'WishcardViewAttachmentsCarousel',
    components: {
        ImageViewerModal,
    },
})
export default class WishcardViewAttachmentsCarousel extends Mixins(
    FileUtilsMixin
) {
    @VModel({ type: Number, required: true })
    private currentIndex: number;

    @Prop({ type: Array, required: true })
    private attachments: File[];

    @Prop({ type: Boolean, default: false })
    private loading: boolean;

    private viewingAttachment: File | null = null;

    private get isViewingAttachment(): boolean {
        return this.viewingAttachment != null;
    }
    private set isViewingAttachment(value: boolean) {
        if (!value) {
            this.viewingAttachment = null;
        }
    }

    private get hasArrowButtons(): boolean {
        return this.$mq != 'xs' && this.$mq != 'sm';
    }

    private get hasPrevious(): boolean {
        return this.currentIndex > 0;
    }
    private get hasNext(): boolean {
        return this.currentIndex < this.attachments.length - 1;
    }

    private viewAttachment(attachment: File): void {
        this.viewingAttachment = attachment;
    }

    private onPrevious(): void {
        if (this.hasPrevious) {
            this.currentIndex--;
        }
    }
    private onNext(): void {
        if (this.hasNext) {
            this.currentIndex++;
        }
    }
}
