import back from '!!raw-loader!@/assets/icons/back.svg';
import camera from '!!raw-loader!@/assets/icons/camera.svg';
import close from '!!raw-loader!@/assets/icons/close.svg';
import deleteIcon from '!!raw-loader!@/assets/icons/delete.svg';
import download from '!!raw-loader!@/assets/icons/download.svg';
import edit from '!!raw-loader!@/assets/icons/edit.svg';
import listViewSelected from '!!raw-loader!@/assets/icons/list-view-selected.svg';
import listViewUnselected from '!!raw-loader!@/assets/icons/list-view-unselected.svg';
import tileViewSelected from '!!raw-loader!@/assets/icons/tile-view-selected.svg';
import tileViewUnselected from '!!raw-loader!@/assets/icons/tile-view-unselected.svg';
import lockClosed from '!!raw-loader!@/assets/icons/lock-closed.svg';
import lockOpen from '!!raw-loader!@/assets/icons/lock-open.svg';
import sort from '!!raw-loader!@/assets/icons/sort.svg';
import upload from '!!raw-loader!@/assets/icons/upload.svg';
import view from '!!raw-loader!@/assets/icons/view.svg';
import valacBox from '!!raw-loader!@/assets/icons/valac-box.svg';
import valac from '!!raw-loader!@/assets/icons/valac.svg';

export const customIcons = {
    back,
    camera,
    close,
    download,
    edit,
    sort,
    upload,
    view,
    valac,
    delete: deleteIcon,
    'list-view-selected': listViewSelected,
    'list-view-unselected': listViewUnselected,
    'tile-view-selected': tileViewSelected,
    'tile-view-unselected': tileViewUnselected,
    'lock-closed': lockClosed,
    'lock-open': lockOpen,
    'valac-box': valacBox,
};
