import './class-component-hooks';

import Vue from 'vue';
import VueMq from 'vue-mq';
import 'vue-class-component/hooks';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/localization';

import '@/plugins/buefy';
import '@/plugins/custom-icons';
import '@/plugins/vee-validate';

import '@/styles/main.scss';

Vue.use(VueMq, {
    breakpoints: {
        sm: 600,
        md: 960,
        lg: 1264,
        xl: 1904,
    },
    defaultBreakpoint: 'sm',
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
