var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.valacBox)?_c('ValacBoxLayout',{attrs:{"title":_vm.$t('wishcardSubmit.title', [_vm.valacBox.id, _vm.valacBox.name]),"subtitle":_vm.$t('wishcardSubmit.subtitle'),"has-home-link":false}},[(_vm.valacBox.hasExpired)?[_c('span',{staticClass:"wishcard-submit__expired",domProps:{"innerHTML":_vm._s(
                _vm.$t('wishcardSubmit.expired', [
                    _vm.valacBox.name,
                    _vm.valacBox.expirationDate ])
            )}})]:[_c('p',{staticClass:"wishcard-submit__welcome"},[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.welcome'))+" "),_c('b',[_vm._v(_vm._s(_vm.valacBox.id))])]),_c('div',{staticClass:"wishcard-submit__description"},[(_vm.valacBox.hasLogo)?_c('b-image',{staticClass:"wishcard-submit__description__image",attrs:{"src":_vm.valacBoxLogoUrl}}):_vm._e(),_c('ul',{staticClass:"wishcard-submit__description__text"},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.description.1'))+" "),_c('b',[_vm._v(_vm._s(_vm.valacBox.name))])]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.description.2'))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.description.3'))+" "),_c('b',[_vm._v(_vm._s(_vm.valacBox.expirationDate))])])])],1),_c('p',{staticClass:"wishcard-submit__email__description"},[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.email.description'))+" ")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('div',{staticClass:"wishcard-submit__email"},[_c('FieldWithValidation',{attrs:{"name":_vm.$t('wishcardSubmit.email'),"rules":"required|email"}},[_c('b-input',{staticClass:"wishcard-submit__email__input",attrs:{"type":"email","placeholder":_vm.$t('wishcardSubmit.email') + '*',"icon-right":"close-circle","icon-right-clickable":"","loading":!invalid && _vm.wishcardLoading},on:{"icon-right-click":_vm.clearEmail},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('p',{staticClass:"wishcard-submit__email__remark"},[_vm._v(" "+_vm._s(_vm.$t('wishcardSubmit.email.remark'))+" ")])],1),_c('div',{staticClass:"wishcard-submit__buttons"},[_c('b-button',{class:{
                        'wishcard-submit__button--invisible':
                            _vm.isOpeningWishcard,
                    },attrs:{"label":_vm.$t('wishcardSubmit.create'),"type":_vm.isCreatingWishcard ? 'is-primary' : 'is-dark',"disabled":invalid || !_vm.canCreateWishcard},on:{"click":_vm.onClickCreate}}),_c('b-button',{class:{
                        'wishcard-submit__button--invisible':
                            _vm.isCreatingWishcard,
                    },attrs:{"label":_vm.$t('wishcardSubmit.open'),"type":_vm.isOpeningWishcard ? 'is-primary' : 'is-dark',"disabled":invalid || !_vm.canOpenWishcard,"loading":_vm.openLoading},on:{"click":_vm.onClickOpen}})],1)]}}],null,false,3964563016)}),_c('b-collapse',{attrs:{"open":_vm.isFormOpen}},[_c('div',{staticClass:"notification wishcard-submit__form"},[_c('div',{staticClass:"wishcard-submit__form__close"},[_c('a',{on:{"click":_vm.closeForm}},[_c('base-icon',{attrs:{"icon":"close"}})],1)]),_c('WishcardForm',{ref:"wishcardForm",attrs:{"valac-box-id":_vm.valacBox.id,"attachments":_vm.attachments},on:{"update:attachments":function($event){_vm.attachments=$event},"submitted":_vm.onSubmitted},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)])]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }