import { Mutations, Module } from 'vuex-smart-module';

class AuthState {
    isAuthenticated = false;
}

class AuthMutations extends Mutations<AuthState> {
    authenticate() {
        this.state.isAuthenticated = true;
    }
}

export const authStore = new Module({
    state: AuthState,
    mutations: AuthMutations,
});
