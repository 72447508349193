

















import { Component, Vue, Prop } from 'vue-property-decorator';
import ValacBoxLayoutHeader from './ValacBoxLayoutHeader.vue';
import ValacBoxLayoutFooter from './ValacBoxLayoutFooter.vue';

@Component({
    name: 'ValacBoxLayout',
    components: {
        ValacBoxLayoutHeader,
        ValacBoxLayoutFooter,
    },
})
export default class ValacBoxLayout extends Vue {
    @Prop({ type: String, default: 'iValacBOX' })
    private title: string;

    @Prop({ type: String, required: false })
    private subtitle?: string;

    @Prop({ type: Boolean, default: true })
    private hasHomeLink: boolean;

    @Prop({ type: Boolean, required: false })
    private hasBackButton?: boolean;
}
