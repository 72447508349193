import { Vue, Component, Prop } from 'vue-property-decorator';
import type { ValacBox, ValacBoxStatistics } from '@/models';
import api from '@/api';

@Component
export default class ValacBoxListMixin extends Vue {
    @Prop({ type: Array, required: true })
    protected valacBoxes: ValacBox[];

    @Prop({ type: Array, default: () => [] })
    protected statistics: ValacBoxStatistics[];

    @Prop({ type: Boolean, default: false })
    protected loading: boolean;

    protected getValacBoxLogoUrl(valacBox: ValacBox): string {
        return api.valacBoxLogoUrl(valacBox.id);
    }
    protected getValacBoxWishcardCount(valacBox: ValacBox): number | null {
        return (
            this.statistics.find(s => s.valacBoxId === valacBox.id)
                ?.wishcardsCount ?? null
        );
    }

    protected onView(valacBox: ValacBox): void {
        this.$router.push({
            name: 'WishcardList',
            params: { id: valacBox.id },
        });
    }
    protected onSubmit(valacBox: ValacBox): void {
        this.$router.push({
            name: 'WishcardSubmit',
            params: { id: valacBox.id },
        });
    }
}
