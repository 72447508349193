



















import { Component, VModel, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseModal',
})
export default class BaseModal extends Vue {
    @VModel({ type: Boolean, required: true })
    private isActive: boolean;

    private onClose(): void {
        this.isActive = false;
        this.$emit('close');
    }
}
