import Vue from 'vue';
import Vuex from 'vuex';
import { createStore, Module } from 'vuex-smart-module';
import { authStore } from './modules/auth';
import { dashboardStore } from './modules/dashboard';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const root = new Module({
    modules: {
        auth: authStore,
        dashboard: dashboardStore,
    },
});

const store = createStore(root, {
    plugins: [createPersistedState()],
});

export default store;
