
























import { Component, Vue } from 'vue-property-decorator';
import { version } from '@/../package.json';

@Component({
    name: 'ValacBoxLayoutFooter',
})
export default class ValacBoxLayoutFooter extends Vue {
    private get currentVersion(): string {
        return version;
    }
}
