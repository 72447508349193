







































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Column } from '@/models';

@Component({
    name: 'BaseTable',
})
export default class BaseTable<T> extends Vue {
    @Prop({ type: Array, required: true })
    private columns: Column<T>[];

    @Prop({ type: String, required: false })
    private emptyMessage: string;

    @Prop({ type: Boolean, default: false })
    private clickable: boolean;

    @Prop({ type: Boolean, default: false })
    private hideActions: boolean;
}
