

















import { Component, Mixins } from 'vue-property-decorator';
import { numberCompare } from '@/utils';
import type { Column, ValacBox } from '@/models';
import ValacBoxListMixin from '@/components/mixins/ValacBoxListMixin';
import BaseTable from '@/components/common/BaseTable.vue';

@Component({
    name: 'ValacBoxTable',
    components: {
        BaseTable,
    },
})
export default class ValacBoxTable extends Mixins(ValacBoxListMixin) {
    private get columns(): Column<ValacBox>[] {
        return [
            {
                field: 'id',
                label: this.$t('valacBoxTable.id').toString(),
                sortable: true,
            },
            {
                field: 'name',
                label: this.$t('valacBoxTable.name').toString(),
                sortable: true,
            },
            {
                field: 'expirationDate',
                label: this.$t('valacBoxTable.expirationDate').toString(),
                sortable: true,
                customHtml: vb =>
                    vb.hasExpired
                        ? vb.expirationDate
                        : `<span class="tag is-primary">${vb.expirationDate}</span>`,
            },
            {
                field: '',
                label: this.$t('valacBoxTable.wishcardCount').toString(),
                sortable: true,
                customDisplay: vb => this.getValacBoxWishcardCount(vb),
                customSort: (a, b, isAsc) =>
                    numberCompare(
                        this.getValacBoxWishcardCount(a),
                        this.getValacBoxWishcardCount(b),
                        isAsc
                    ),
            },
        ];
    }
}
