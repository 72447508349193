

























































import { Component, Ref, Vue } from 'vue-property-decorator';
import filter from 'lodash/filter';
import uniqueId from 'lodash/uniqueId';
import {
    CAMERA_CAPTURE_PREFIX,
    CAMERA_CAPTURE_EXTENSION,
} from '@/utils/constants';
import BaseModal from './BaseModal.vue';

interface Dimensions {
    width: number;
    height: number;
}

interface Image {
    dataURL: string;
    file: File;
}

function resize(boxSize: Dimensions, imgSize: Dimensions): Dimensions {
    if (imgSize.width <= boxSize.width && imgSize.height <= boxSize.height) {
        return imgSize;
    }

    var width = 0,
        height = 0;
    var wratio = imgSize.width / boxSize.width;
    var hratio = imgSize.height / boxSize.height;

    if (wratio > hratio) {
        width = boxSize.width;
        height = Math.round(imgSize.height / wratio);
    } else {
        height = boxSize.height;
        width = Math.round(imgSize.width / hratio);
    }

    return { width, height };
}

function dataURLtoFile(dataurl: string, filename: string): File {
    var arr = dataurl.split(','),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

function getRandomFileName(): string {
    return uniqueId(CAMERA_CAPTURE_PREFIX) + CAMERA_CAPTURE_EXTENSION;
}

@Component({
    name: 'CameraModal',
    components: {
        BaseModal,
    },
})
export default class CameraModal extends Vue {
    @Ref()
    readonly video: HTMLVideoElement;

    private showDialog = false;
    private haveAccessToCamera = false;
    private cameraDevices: MediaDeviceInfo[] = [];
    private cameraDeviceIndex = 0;
    private disposition = 'horizontal';
    private dimensions = {
        horizontal: {
            width: 600,
            height: 400,
        },
        vertical: {
            width: 400,
            height: 600,
        },
    };
    private capturing = false;

    private get canFlipCamera(): boolean {
        return this.cameraDevices.length > 1;
    }
    private get dimensionsStyle() {
        let d =
            this.disposition == 'horizontal'
                ? this.dimensions.horizontal
                : this.dimensions.vertical;
        return {
            width: `${d.width}px`,
            height: `${d.height}px`,
        };
    }

    public mounted(): void {
        if (window.matchMedia('(max-width: 576px)').matches) {
            this.disposition = 'vertical';
        }

        this.calcDimensions();
        window.addEventListener('resize', this._onResize);
    }
    public beforeDestroy(): void {
        window.removeEventListener('resize', this._onResize);
    }

    private calcDimensions(): void {
        const bigScreen = window.matchMedia('(min-width: 960px)').matches;

        const win_size = {
            width:
                (bigScreen ? window.innerWidth * 0.75 : window.innerWidth) - 50,
            height:
                (bigScreen ? window.innerHeight * 0.75 : window.innerHeight) -
                50,
        };

        // horizontal
        this.dimensions.horizontal = resize(win_size, {
            width: win_size.width,
            height: win_size.width * (9 / 16),
        });

        // vertical
        this.dimensions.vertical = resize(win_size, {
            width: win_size.width,
            height: win_size.width * (16 / 9),
        });
    }
    private rotate(): void {
        this.disposition =
            this.disposition == 'horizontal' ? 'vertical' : 'horizontal';
    }
    private closeDialog(): void {
        this.showDialog = false;
        this.capturing = false;
        this.stopCurrentStream();
    }
    private emitConfirm(image: Image): void {
        this.$emit('confirm', image);
        this.closeDialog();
    }
    private emitCancel(): void {
        this.$emit('cancel');
        this.closeDialog();
    }
    public async requestCapture(): Promise<void> {
        this.showDialog = true;
        try {
            let stream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });
            this.video.srcObject = stream;
            this.video.play();
            this.haveAccessToCamera = true;
            this.enumerateCameras();
        } catch (err) {
            console.log(err);
        }
    }
    private async enumerateCameras(): Promise<void> {
        try {
            let devices = await navigator.mediaDevices.enumerateDevices();
            this.cameraDevices = filter(devices, d => d.kind == 'videoinput');
        } catch (err) {
            console.log(err);
        }
    }
    private async flipCamera(): Promise<void> {
        this.stopCurrentStream();

        let index = this.cameraDeviceIndex + 1;
        if (index >= this.cameraDevices.length) {
            index = 0;
        }
        this.cameraDeviceIndex = index;

        try {
            let device = this.cameraDevices[index];
            let stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: {
                        exact: device.deviceId,
                    },
                },
            });
            this.video.srcObject = stream;
            this.video.play();
        } catch (err) {
            console.log(err);
        }
    }
    private confirmCapture(): void {
        if (!this.haveAccessToCamera) return;

        this.video.pause();
        this.capturing = true;
    }
    private continueCapturing(): void {
        this.video.play();
        this.capturing = false;
    }
    private getCapture(): Image | undefined {
        if (!this.haveAccessToCamera) return undefined;

        let canvas = document.createElement('canvas');
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        let ctx = canvas.getContext('2d');
        ctx?.drawImage(this.video, 0, 0);
        let dataURL = canvas.toDataURL('image/jpeg');
        let file = dataURLtoFile(dataURL, getRandomFileName());

        return { dataURL, file };
    }
    private doCapture(): void {
        let image = this.getCapture();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.emitConfirm(image!);
    }
    private stopCurrentStream(): void {
        try {
            this.video.pause();
            let stream = this.video.srcObject;
            if (stream != null) {
                (stream as MediaStream).getTracks().forEach(t => t.stop());
                this.video.srcObject = null;
            }
        } catch (err) {
            console.log(err);
        }
    }

    private _onResize(): void {
        this.calcDimensions();
    }
}
