



































































































import {
    Vue,
    Component,
    VModel,
    Ref,
    PropSync,
    Prop,
} from 'vue-property-decorator';
import _ from 'lodash';
import api from '@/api';
import { toast } from '@/utils';
import {
    CAMERA_CAPTURE_PREFIX,
    CAMERA_CAPTURE_EXTENSION,
} from '@/utils/constants';
import type { WishcardParameters } from '@/models';
import { ValidationObserver } from 'vee-validate';
import FieldWithValidation from '@/components/common/FieldWithValidation.vue';
import CameraModal from '@/components/modals/CameraModal.vue';
import FileCarousel from './WischardFormFileCarousel.vue';

@Component({
    name: 'WishcardForm',
    components: {
        ValidationObserver,
        FieldWithValidation,
        CameraModal,
        FileCarousel,
    },
})
export default class WishcardForm extends Vue {
    @VModel({ type: Object, required: true })
    private parameters: WishcardParameters;

    @Prop({ type: String, required: true })
    private valacBoxId: string;

    @PropSync('attachments', { type: Array, required: true })
    private syncedAttachments: File[];

    @Ref()
    private formObserver: InstanceType<typeof ValidationObserver>;

    @Ref()
    private cameraModal: CameraModal;

    private submitLoading = false;
    private submitFailed = false;

    private get isSmallScreen(): boolean {
        return this.$mq == 'sm' || this.$mq == 'xs';
    }

    public resetForm(): void {
        this.formObserver.reset();
    }

    private requestCapture(): void {
        this.cameraModal.requestCapture();
    }
    private onConfirmCapture(img: { file: File }): void {
        let file = img.file;

        const existingCaptureIds = this.syncedAttachments
            .filter(
                a =>
                    a.name.startsWith(CAMERA_CAPTURE_PREFIX) &&
                    a.name.endsWith(CAMERA_CAPTURE_EXTENSION)
            )
            .map(a =>
                Number.parseInt(
                    a.name.substring(
                        CAMERA_CAPTURE_PREFIX.length,
                        a.name.length - CAMERA_CAPTURE_EXTENSION.length
                    )
                )
            );

        const maxId = _.max(existingCaptureIds);

        if (
            maxId != null &&
            maxId <=
                Number.parseInt(
                    file.name.substring(
                        CAMERA_CAPTURE_PREFIX.length,
                        file.name.length - CAMERA_CAPTURE_EXTENSION.length
                    )
                )
        ) {
            const fileName = `${CAMERA_CAPTURE_PREFIX}${
                maxId + 1
            }${CAMERA_CAPTURE_EXTENSION}`;

            file = new File([file], fileName, {
                type: file.type,
                lastModified: file.lastModified,
            });
        }

        this.syncedAttachments.push(file);
    }

    private onAttachmentsChange(): void {
        this.syncedAttachments = _.uniqBy(this.syncedAttachments, 'name');
    }

    private async submit(): Promise<void> {
        this.submitLoading = true;
        this.submitFailed = false;

        try {
            await api.submitValacBoxWishcard(
                this.valacBoxId,
                this.parameters,
                this.syncedAttachments
            );

            toast(
                this.$t('wishcardForm.submit.success').toString(),
                'is-success'
            );

            this.$emit('submitted');
        } catch (error) {
            this.submitFailed = true;
        } finally {
            this.submitLoading = false;
        }
    }
}
