









































import { Component, Mixins } from 'vue-property-decorator';
import ValacBoxListMixin from '@/components/mixins/ValacBoxListMixin';

@Component({
    name: 'ValacBoxGrid',
})
export default class ValacBoxGrid extends Mixins(ValacBoxListMixin) {}
