import { Mutations, Module } from 'vuex-smart-module';
import type { ViewMode } from '@/models';

class DashboardState {
    searchString = '';
    viewMode: ViewMode = 'grid';
}

class DashboardMutations extends Mutations<DashboardState> {
    setSearchString(searchString: string): void {
        this.state.searchString = searchString;
    }
    setViewMode(viewMode: ViewMode): void {
        this.state.viewMode = viewMode;
    }
}

export const dashboardStore = new Module({
    state: DashboardState,
    mutations: DashboardMutations,
});
