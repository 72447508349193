



















import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';
import BaseModal from './BaseModal.vue';
import FileUtilsMixin from '@/components/mixins/FileUtilsMixin';

@Component({
    name: 'ImageViewerModal',
    components: {
        BaseModal,
    },
})
export default class ImageViewerModal extends Mixins(FileUtilsMixin) {
    @VModel({ type: Boolean, required: true })
    private isActive: boolean;

    @Prop({ type: File, required: false })
    private file?: File;
}
