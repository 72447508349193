import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import { registerGlobalAuthGuard } from './registerGlobalAuthGuard';
import { registerMatomo } from './registerMatomo';
import store from '@/store';

import Dashboard from '../views/Dashboard.vue';

import WishcardList from '../views/wishcards/WishcardList.vue';
import WishcardSubmit from '../views/wishcards/WishcardSubmit.vue';
import WishcardView from '../views/wishcards/WishcardView.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        component: Dashboard,
    },

    {
        path: '/:id([\\d\\w]+)',
        name: 'WishcardList',
        meta: { requiresAuth: true },
        component: WishcardList,
    },
    {
        path: '/:id([\\d\\w]+)/submit',
        name: 'WishcardSubmit',
        alias: '/:id([\\d\\w]+)/edit',
        component: WishcardSubmit,
    },
    {
        path: '/:id([\\d\\w]+)/view/:email([\\S]+)',
        name: 'WishcardView',
        meta: { requiresAuth: true },
        component: WishcardView,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

registerGlobalAuthGuard(router, store);
registerMatomo(router);

export default router;
