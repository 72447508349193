











































import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';
import FileUtilsMixin from '@/components/mixins/FileUtilsMixin';

@Component({
    name: 'WishcardViewAttachmentsGallery',
})
export default class WishcardViewAttachmentsGallery extends Mixins(
    FileUtilsMixin
) {
    @VModel({ type: Number, required: true })
    private currentIndex: number;

    @Prop({ type: Array, required: true })
    private attachments: File[];

    @Prop({ type: Boolean, default: false })
    private loading: boolean;

    private isViewingFile(ind: number): boolean {
        return this.currentIndex == ind;
    }

    private viewFile(ind: number): void {
        this.currentIndex = ind;
    }
}
