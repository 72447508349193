








































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import FieldWithValidation from '../common/FieldWithValidation.vue';

@Component({
    name: 'PasswordModal',
    components: {
        ValidationObserver,
        FieldWithValidation,
    },
})
export default class PasswordModal extends Vue {
    private password = '';
    private submitFailed = false;

    private onSubmit(): void {
        this.submitFailed = false;

        if (this.password == 'Dani') {
            this.$emit('login');
            this.$emit('close');
        } else {
            this.submitFailed = true;
        }
    }
}
