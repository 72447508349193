































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ValacBoxLayoutHeader',
})
export default class ValacBoxLayoutHeader extends Vue {
    @Prop({ type: String, required: true })
    private title: string;

    @Prop({ type: String, required: false })
    private subtitle?: string;

    @Prop({ type: Boolean, default: true })
    private hasHomeLink: boolean;

    @Prop({ type: Boolean, default: false })
    private hasBackButton: boolean;

    private get isCompactHeader(): boolean {
        return this.isSmallScreen || (!this.subtitle && !this.hasBackButton);
    }

    private get isSmallScreen(): boolean {
        return this.$mq == 'xs' || this.$mq == 'sm';
    }

    private goBack(): void {
        this.$router.go(-1);
    }

    private onClickHome(): void {
        if (this.hasHomeLink) {
            this.$router.push('/');
        }
    }
}
