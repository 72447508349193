var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValacBoxLayout',{attrs:{"title":_vm.$t('dashboard.title'),"has-home-link":false}},[_c('p',{staticClass:"dashboard__description"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.description'))+" ")]),_c('div',{staticClass:"dashboard__actions"},[_c('b-input',{staticClass:"dashboard__search",attrs:{"placeholder":_vm.$t('dashboard.search'),"type":"search","icon-right":"magnify"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('div',{staticClass:"dashboard__view-mode"},[_c('div',{staticClass:"dashboard__view-mode__text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.view'))+" ")]),_c('a',{staticClass:"dashboard__view-mode__icon",class:{
                    'dashboard__view-mode__icon--inactive':
                        _vm.viewMode != 'grid',
                },on:{"click":function($event){_vm.viewMode = 'grid'}}},[_c('base-icon',{attrs:{"icon":_vm.viewMode == 'grid'
                            ? 'tile-view-selected'
                            : 'tile-view-unselected'}})],1),_c('a',{staticClass:"dashboard__view-mode__icon",class:{
                    'dashboard__view-mode__icon--inactive':
                        _vm.viewMode != 'table',
                },on:{"click":function($event){_vm.viewMode = 'table'}}},[_c('base-icon',{attrs:{"icon":_vm.viewMode == 'table'
                            ? 'list-view-selected'
                            : 'list-view-unselected'}})],1)])],1),(_vm.viewMode == 'grid')?_c('ValacBoxGrid',{attrs:{"valac-boxes":_vm.filteredValacBoxes,"statistics":_vm.statistics,"loading":_vm.valacBoxesLoading}}):(_vm.viewMode == 'table')?_c('ValacBoxTable',{attrs:{"valac-boxes":_vm.filteredValacBoxes,"statistics":_vm.statistics,"loading":_vm.valacBoxesLoading}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }