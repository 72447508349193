


















































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import api from '@/api';
import type { ValacBox, Wishcard } from '@/models';
import type { NavigationGuardNext, Route } from 'vue-router';
import FileUtilsMixin from '@/components/mixins/FileUtilsMixin';
import ValacBoxLayout from '@/components/layouts/ValacBoxLayout.vue';
import WishcardTable from '@/components/wishcards/WishcardTable.vue';

@Component({
    name: 'WishcardList',
    components: {
        ValacBoxLayout,
        WishcardTable,
    },
})
export default class WishcardList extends Mixins(FileUtilsMixin) {
    private valacBox: ValacBox | null = null;
    private wishcards: Wishcard[] = [];
    private attachments: File[] = [];
    private wishcardsLoading = false;
    private attachmentsLoading = false;
    private showGallery = false;
    private galleryInd = 0;

    private searchString = '';

    private get valacBoxId(): string {
        return this.$route.params.id;
    }
    private get valacBoxLogoUrl(): string {
        return api.valacBoxLogoUrl(this.valacBoxId);
    }

    private get filteredWishcards(): Wishcard[] {
        const searchString = this.searchString.toUpperCase();

        if (searchString.length == 0) {
            return this.wishcards;
        }

        return this.wishcards.filter(
            w =>
                w.sender.toUpperCase().includes(searchString) ||
                w.title.toUpperCase().includes(searchString) ||
                w.email.includes(searchString)
        );
    }

    private get playLoading(): boolean {
        return this.showGallery && this.attachmentsLoading;
    }

    private get isGalleryVisible(): boolean {
        return this.showGallery && !this.attachmentsLoading;
    }

    @Watch('isGalleryVisible')
    private onGalleryVisibilityChange(): void {
        const rootElement = document.firstElementChild;

        if (this.isGalleryVisible) {
            rootElement?.classList.add('is-clipped');
        } else {
            rootElement?.classList.remove('is-clipped');
        }
    }

    public async beforeRouteEnter(
        to: Route,
        from: Route,
        next: NavigationGuardNext<WishcardList>
    ): Promise<void> {
        const valacBox = await api.getValacBox(to.params.id);

        next((vm: WishcardList) => (vm.valacBox = valacBox));
    }

    public created(): void {
        this.initialize();
    }

    private async initialize(): Promise<void> {
        await this.fetchWishcards();
        await this.fetchImageAttachments();
    }

    private async fetchWishcards(): Promise<void> {
        this.wishcardsLoading = true;

        this.wishcards = await api.getValacBoxWishcardList(this.valacBoxId);

        this.wishcardsLoading = false;
    }
    private async fetchImageAttachments(): Promise<void> {
        this.attachmentsLoading = true;
        this.attachments = [];

        for (const wishcard of this.wishcards) {
            for (const attachment of wishcard.attachments) {
                if (!this.isImageType(attachment.contentType)) {
                    continue;
                }

                const file = await api.getValacBoxWishcardFile(
                    this.valacBoxId,
                    wishcard.email,
                    attachment.name
                );

                if (file != null) {
                    this.attachments.push(file);
                }
            }
        }

        this.attachmentsLoading = false;
    }
}
