




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
    name: 'FieldWithValidation',
    inheritAttrs: false,
    components: {
        ValidationProvider,
    },
})
export default class FieldWithValidation extends Vue {
    @Prop({ type: String, required: false })
    private name: string;

    @Prop({ type: String, required: false })
    private label: string;

    @Prop({ type: String, required: false })
    private vid?: string;

    @Prop({ type: [String, Object], required: false })
    private rules?: any;

    @Prop({ type: String, required: false })
    private mode?: string;

    private get validationName(): string {
        return this.name ?? this.label;
    }
}
