import VueRouter from 'vue-router';
const matomo = !!document.querySelector('html')?.getAttribute('data-matomo-id');

export function registerMatomo(router: VueRouter): void {
    router.beforeEach((to, from, next) => {
        if (matomo) {
            const _paq = (window as any)._paq || [];
            const location = window.location.href.split('#')[0];
            const currentUrl = to.fullPath;
            _paq.push(['setReferrerUrl', location]);
            _paq.push(['setCustomUrl', currentUrl]);
            _paq.push(['deleteCustomVariables', 'page']);
            _paq.push(['setPagePerformanceTiming', 0]);
            _paq.push(['trackPageView']);
        }
        next();
    });
}
