type numberOrNull = number | null | undefined;

export function numberCompare(
    number1: numberOrNull,
    number2: numberOrNull,
    isAsc: boolean = true
): number {
    if (number1 == null || number2 == null) {
        if (number1 == null && number2 == null) {
            return 0;
        }
        return number1 == null ? 1 : -1;
    }
    return isAsc ? number1 - number2 : number2 - number1;
}
