import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/localization/messages/en';

const messages = {
    en,
};

Vue.use(VueI18n);

const locale = 'en';
const fallbackLocale = 'en';

export const i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages,
});
