import api from './api';
import type {
    ValacBox,
    WishcardParameters,
    Wishcard,
    ValacBoxStatistics,
} from '@/models';
const BASE_URL = 'ValacBoxes';

export default {
    async getValacBoxList(): Promise<ValacBox[]> {
        return (await api.get(BASE_URL)).data;
    },
    async getValacBox(id: string): Promise<ValacBox> {
        return (await api.get(`${BASE_URL}/${id}`)).data;
    },
    async getValacBoxStats(): Promise<ValacBoxStatistics[]> {
        return (await api.get(`${BASE_URL}/Stats`)).data;
    },
    async createValacBox(valacBox: ValacBox): Promise<ValacBox> {
        return (await api.post(BASE_URL, valacBox)).data;
    },
    async updateValacBox(id: string, valacBox: ValacBox): Promise<ValacBox> {
        return (await api.put(`${BASE_URL}/${id}`, valacBox)).data;
    },
    async deleteValacBox(id: string): Promise<void> {
        await api.delete(`${BASE_URL}/${id}`);
    },
    valacBoxLogoUrl(id: string): string {
        return `${api.defaults.baseURL}${BASE_URL}/${id}/Logo`;
    },

    async getValacBoxWishcardList(id: string): Promise<Wishcard[]> {
        return (await api.get(`${BASE_URL}/${id}/Wishcards`)).data;
    },
    async getValacBoxWishcard(id: string, email: string): Promise<Wishcard> {
        return (await api.get(`${BASE_URL}/${id}/Wishcards/${email}`)).data;
    },
    async getValacBoxWishcardFile(
        id: string,
        email: string,
        fileName: string
    ): Promise<File | null> {
        try {
            const response = await api.get(
                `${BASE_URL}/${id}/Wishcards/${email}/Files/${fileName}`,
                {
                    responseType: 'blob',
                }
            );

            return new File([response.data], fileName, {
                type: response.headers['content-type'],
            });
        } catch (error) {
            return null;
        }
    },
    async submitValacBoxWishcard(
        id: string,
        paramenters: WishcardParameters,
        attachments: File[]
    ): Promise<Wishcard> {
        const formData = new FormData();

        for (const [key, value] of Object.entries(paramenters)) {
            formData.append(key, value);
        }

        for (const attachment of attachments) {
            formData.append('attachments', attachment);
        }

        return (
            await api.put(`${BASE_URL}/${id}/Wishcards`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        ).data;
    },
    async deleteValacBoxWishcard(id: string, email: string): Promise<void> {
        await api.delete(`${BASE_URL}/${id}/Wishcards/${email}`);
    },
};
