







































































import { Vue, Component } from 'vue-property-decorator';
import api from '@/api';
import { dashboardStore } from '@/store/modules/dashboard';
import type { ValacBox, ValacBoxStatistics, ViewMode } from '@/models';
import ValacBoxLayout from '@/components/layouts/ValacBoxLayout.vue';
import ValacBoxGrid from '@/components/valac-boxes/ValacBoxGrid.vue';
import ValacBoxTable from '@/components/valac-boxes/ValacBoxTable.vue';

@Component({
    name: 'Dashboard',
    components: {
        ValacBoxLayout,
        ValacBoxGrid,
        ValacBoxTable,
    },
})
export default class Dashboard extends Vue {
    private dashboardCtx = dashboardStore.context(this.$store);

    private valacBoxes: ValacBox[] = [];
    private statistics: ValacBoxStatistics[] = [];
    private valacBoxesLoading = false;

    private get searchString(): string {
        return this.dashboardCtx.state.searchString;
    }
    private set searchString(value: string) {
        this.dashboardCtx.commit('setSearchString', value);
    }

    private get viewMode(): ViewMode {
        return this.dashboardCtx.state.viewMode;
    }
    private set viewMode(value: ViewMode) {
        this.dashboardCtx.commit('setViewMode', value);
    }

    private get filteredValacBoxes(): ValacBox[] {
        const searchString = this.searchString.toUpperCase();

        if (searchString.length == 0) {
            return this.valacBoxes;
        }

        return this.valacBoxes.filter(
            vb =>
                vb.id.toUpperCase().includes(searchString) ||
                vb.name.toUpperCase().includes(searchString) ||
                vb.expirationDate.includes(searchString)
        );
    }

    public created(): void {
        this.fetchValacBoxes();
    }

    private async fetchValacBoxes(): Promise<void> {
        this.valacBoxesLoading = true;

        [this.valacBoxes, this.statistics] = await Promise.all([
            api.getValacBoxList(),
            api.getValacBoxStats(),
        ]);

        this.valacBoxesLoading = false;
    }

    private onCreate(): void {
        // TODO
    }
}
