import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FileUtilsMixin extends Vue {
    protected isImageType(type: string): boolean {
        return type.startsWith('image/');
    }
    protected isVideoType(type: string): boolean {
        return type.startsWith('video/');
    }

    protected isImageFile(file: File): boolean {
        return this.isImageType(file.type);
    }
    protected isVideoFile(file: File): boolean {
        return this.isVideoType(file.type);
    }

    protected getFileUrl(file: File): string {
        return URL.createObjectURL(file);
    }
}
