







































import { Vue, Component, Watch } from 'vue-property-decorator';
import api from '@/api';
import type { ValacBox, Wishcard } from '@/models';
import type { Route, NavigationGuardNext } from 'vue-router';
import ValacBoxLayout from '@/components/layouts/ValacBoxLayout.vue';
import AttachmentsCarousel from '@/components/wishcards/WishcardViewAttachmentsCarousel.vue';
import AttachmentsGallery from '@/components/wishcards/WishcardViewAttachmentsGallery.vue';

@Component({
    name: 'WishcardView',
    components: {
        ValacBoxLayout,
        AttachmentsCarousel,
        AttachmentsGallery,
    },
})
export default class WishcardView extends Vue {
    private valacBox: ValacBox | null = null;
    private wishcard: Wishcard | null = null;
    private attachments: File[] = [];
    private attachmentsLoading = false;

    private viewingIndex = 0;

    private get valacBoxId(): string {
        return this.$route.params.id;
    }

    private get subtitle(): string {
        return `${this.valacBoxId} - ${this.wishcard?.sender}`;
    }

    private get hasAttachments(): boolean {
        return this.wishcard != null && this.wishcard.attachments.length > 0;
    }

    @Watch('wishcard')
    private onWishcardChange(): void {
        this.fetchAttachments();
    }

    public async beforeRouteEnter(
        to: Route,
        from: Route,
        next: NavigationGuardNext<WishcardView>
    ): Promise<void> {
        const [valacBox, wishcard] = await Promise.all([
            api.getValacBox(to.params.id),
            api.getValacBoxWishcard(to.params.id, to.params.email),
        ]);

        next((vm: WishcardView) => {
            vm.valacBox = valacBox;
            vm.wishcard = wishcard;
        });
    }

    private async fetchAttachments(): Promise<void> {
        const wishcard = this.wishcard;

        if (wishcard == null) {
            return;
        }

        this.attachmentsLoading = true;
        this.attachments = [];

        for (const attachment of wishcard.attachments) {
            const file = await api.getValacBoxWishcardFile(
                this.valacBoxId,
                wishcard.email,
                attachment.name
            );

            if (file != null) {
                this.attachments.push(file);
            }
        }

        this.attachmentsLoading = false;
    }
}
