












import { Vue, Component, Prop } from 'vue-property-decorator';
import { numberCompare } from '@/utils';
import type { Column, Wishcard } from '@/models';
import BaseTable from '@/components/common/BaseTable.vue';

@Component({
    name: 'WishcardTable',
    components: {
        BaseTable,
    },
})
export default class WishcardTable extends Vue {
    @Prop({ type: Array, required: true })
    private wishcards: Wishcard[];

    @Prop({ type: Boolean, default: false })
    private loading: boolean;

    @Prop({ type: String, required: true })
    private valacBoxId: string;

    private get columns(): Column<Wishcard>[] {
        return [
            {
                field: 'sender',
                label: this.$t('wishcardTable.sender').toString(),
                sortable: true,
            },
            {
                field: 'title',
                label: this.$t('wishcardTable.title').toString(),
                sortable: true,
            },
            {
                field: 'email',
                label: this.$t('wishcardTable.email').toString(),
                sortable: true,
            },
            {
                field: 'attachments',
                label: this.$t('wishcardTable.attachmentCount').toString(),
                sortable: true,
                customDisplay: wishcard => wishcard.attachments.length,
                customSort: (a, b, isAsc) =>
                    numberCompare(
                        a.attachments.length,
                        b.attachments.length,
                        isAsc
                    ),
            },
        ];
    }

    private onView(wishcard: Wishcard): void {
        this.$router.push({
            name: 'WishcardView',
            params: { id: this.valacBoxId, email: wishcard.email },
        });
    }
}
