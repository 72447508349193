












































import { Component, Mixins, VModel } from 'vue-property-decorator';
import FileUtilsMixin from '@/components/mixins/FileUtilsMixin';
import ImageViewerModal from '@/components/modals/ImageViewerModal.vue';

@Component({
    name: 'WishcardFormFileCarousel',
    components: {
        ImageViewerModal,
    },
})
export default class WishcardFormFileCarousel extends Mixins(FileUtilsMixin) {
    @VModel({ type: Array, required: true })
    private files: File[];

    private viewingFile: File | null = null;

    private get itemsToShow(): number {
        switch (this.$mq) {
            case 'xs':
            case 'sm':
                return 2;
            case 'md':
                return 4;
            case 'lg':
                return 6;
            default:
                return 8;
        }
    }

    private get isViewingFile(): boolean {
        return this.viewingFile != null;
    }
    private set isViewingFile(value: boolean) {
        if (!value) {
            this.viewingFile = null;
        }
    }

    private get carouselData(): any {
        return this.files.map(f => ({
            title: f.name,
            url: this.getFileUrl(f),
            type: f.type,
        }));
    }

    private viewFile(file: File): void {
        this.viewingFile = file;
    }

    private deleteFile(index: number): void {
        this.files.splice(index, 1);
    }
}
