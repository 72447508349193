






import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'App',
    metaInfo: {
        titleTemplate: titleChunk => {
            return titleChunk ? `${titleChunk} | iValacBOX` : 'iValacBOX';
        },
    },
})
export default class App extends Vue {}
