import { ToastProgrammatic as Toast } from 'buefy';

export function toast(message: string, type?: string): void {
    Toast.open({
        type: type,
        message: message,
        position: 'is-bottom',
        duration: 5000,
        queue: false,
    });
}
