









import { Component, Prop, Vue } from 'vue-property-decorator';
import { customIcons } from '@/utils';

@Component({
    name: 'BaseIcon',
})
export default class BaseIcon extends Vue {
    @Prop({ type: String, required: true })
    private icon: string;

    @Prop({ type: String, required: false })
    private size: string;

    @Prop({ type: String, required: false })
    private customSize: string;

    private get iconSvg(): string {
        return (customIcons as any)[this.icon] ?? '';
    }

    private get iconSizeClass(): string {
        return this.size ? `base-icon--${this.size}` : '';
    }
}
